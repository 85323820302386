<!-- eslint-disable max-len -->
<template>
  <div class="common-statuses-main-wrapper">
    <check-circle-two-tone
      v-if="(status === STATUS.STATUS.ACCEPTED)"
      two-tone-color="#52c41a"
      :style="{
        fontSize: '18px',
        marginLeft: '5px',
      }"
    />
    <close-circle-two-tone
      v-if="(status === STATUS.STATUS.REJECTED)"
      two-tone-color="#FF0000"
      :style="{
        fontSize: '18px',
        marginLeft: '5px',
      }"
    />
    <check-circle-two-tone
      v-if="(status === STATUS.STATUS.PENDING_APPROVAL)"
      :style="{
        fontSize: '18px',
        marginLeft: '5px',
      }"
    />
  </div>
</template>
<script>
/* eslint-disable */
import { ref, watch } from "vue";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons-vue";
import STATUS from "../../../constants/approvalStages";

export default {
  components: {
    CheckCircleTwoTone,
    CloseCircleTwoTone,
  },
  props: ['type'],
  setup(props, context) {
    const status = ref('');
    watch(() => {
      status.value = props.type;
    });
    return {
      status,
      STATUS,
    };
  },
};
</script>
