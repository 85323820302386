<!-- eslint-disable max-len -->
<template>
  <div class="admin-dashboard-kyc-approval-main-wrapper">
    <div class="accept-kyc-buttons-wrapper">
      <div
        v-if="activateKyc"
        class="accept-kyc-btn-wrapper"
        style="margin-right: 15px; margin-left: 0px;"
      >
        <a-button :disabled="acceptKycDisableState" @click="acceptKyc()"
          >Accept KYC</a-button
        >
      </div>
      <div
        v-if="activateKyc"
        class="accept-kyc-btn-wrapper"
        style="margin-right: 15px; margin-left: 0px;"
      >
        <a-button :disabled="acceptKycDisableState" @click="syncToKra()"
          >Push to KRA</a-button
        >
      </div>
      <div
        v-if="bowActivate"
        class="bow-activate-btn-wrapper"
        style="margin-right: 15px; margin-left: 0px;"
      >
        <a-button :disabled="bowActiveDisable" @click="bowUserActivated()"
          >Activate BOW</a-button
        >
      </div>
      <div
        v-if="!closeAccountDisable"
        class="bow-activate-btn-wrapper"
        style="margin-left: 0px;"
      >
        <a-button @click="closeAccount()"
          >Close Account</a-button
        >
      </div>
    </div>
    <div class="kyc-approval-common-section-main-wrapper">
      <div class="kyc-approval-common-title-text">Client Details</div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Name:</div>
            <div class="client-details-key-text">DOB:</div>
            <div class="client-details-key-text">UCC Code:</div>
            <div class="client-details-key-text">Mobile No:</div>
            <div class="client-details-key-text">Email ID:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">{{ panName }}</div>
            <div class="client-details-response-text">{{ dateOfBirth }}</div>
            <div class="client-details-response-text">{{ uccCode }}</div>
            <div
              class="client-details-response-text"
              style="display: flex; align-items: center"
            >
              {{ mobileNumber }} <Status :type="statusType.VALIDATE_MOBILE" />
            </div>
            <div
              class="client-details-response-text"
              style="display: flex; align-items: center"
            >
              {{ emailId }} <Status :type="statusType.VALIDATE_EMAIL" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class='kyc-approval-common-approve-reject-btn-wrapper'>
            <div class='approve-btn-wrapper' style='margin-right:15px'>
                <a-button>Approve</a-button></div>
            <div class='reject-btn-wrapper'><a-button @click='onClickReject'>Reject</a-button></div>
        </div> -->
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.PAN_VALIDATION)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        PAN <Status :type="statusType.PAN_VALIDATION" />
        <div
          v-if="statusType.PAN_VALIDATION === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ panReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div class="client-details-key-text">PAN Number : {{ panNumber }}</div>
        <div class="kyc-approval-common-image-wrapper">
          <img
            style="max-width: 400px; width: 100%; object-fit: cover"
            :src="panImage"
          />
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.PAN_VALIDATION)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.PAN_VALIDATION)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.ADDRESS_PROOF)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Aadhaar <Status :type="statusType.ADDRESS_PROOF" />
        <div
          v-if="statusType.ADDRESS_PROOF === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ aadharReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div class="client-details-key-text">
          Aadhaar Number : {{ aadhaarNumber }}
        </div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>Aadhaar.pdf</div>
          <div style="margin-left: 50px">
            <a :href="aadharFrontImage" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.ADDRESS_PROOF)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.ADDRESS_PROOF)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.KRA)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        KRA <Status :type="statusType.KRA" />
        <div
          v-if="statusType.KRA === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ kraReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Kyc Status:</div>
            <div class="client-details-key-text">Name:</div>
            <div class="client-details-key-text">Gender:</div>
            <div class="client-details-key-text">DOB:</div>
            <div class="client-details-key-text">Marital Status:</div>
            <div class="client-details-key-text">Mobile:</div>
            <div class="client-details-key-text">Email:</div>
            <div class="client-details-key-text">Father Name:</div>
            <div class="client-details-key-text">Pan:</div>
            <div class="client-details-key-text">Nationality:</div>
            <div class="client-details-key-text">Resident Status:</div>
            <div class="client-details-key-text">Annual Income:</div>
            <div class="client-details-key-text">Occupation:</div>
            <div class="client-details-key-text">Politically Exposed:</div>
            <div class="client-details-key-text">City:</div>
            <div class="client-details-key-text">Pin code:</div>
            <div class="client-details-key-text">State:</div>
            <div class="client-details-key-text">Country:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">
              {{ kraDetails.kycStatus }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.name }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.gender }}
            </div>
            <div class="client-details-response-text">{{ kraDetails.dob }}</div>
            <div class="client-details-response-text">
              {{ kraDetails.maritialStatus }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.mobile }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.email }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.fatherName }}
            </div>
            <div class="client-details-response-text">{{ kraDetails.pan }}</div>
            <div class="client-details-response-text">
              {{ kraDetails.nationality }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.residentStatus }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.annualIncome }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.occupation }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.politicallyExposed }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.city }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.pincode }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.state }}
            </div>
            <div class="client-details-response-text">
              {{ kraDetails.country }}
            </div>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.PAN_VALIDATION)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.PAN_VALIDATION)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.BANK_DETAILS)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Bank Details <Status :type="statusType.BANK_DETAILS" />
        <div
          v-if="statusType.BANK_DETAILS === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ bankReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Bank Name:</div>
            <div class="client-details-key-text">IFSC:</div>
            <div class="client-details-key-text">Account number:</div>
            <div class="client-details-key-text">Account type:</div>
            <div class="client-details-key-text">MICR:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">
              {{ bankDetails.name }}
            </div>
            <div class="client-details-response-text">
              {{ bankDetails.ifsc }}
            </div>
            <div class="client-details-response-text">
              {{ bankDetails.account }}
            </div>
            <div class="client-details-response-text">
              {{ bankDetails.type }}
            </div>
            <div class="client-details-response-text">
              {{ bankDetails.micr }}
            </div>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.BANK_DETAILS)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.BANK_DETAILS)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.CHEQUE_VERIFICATION)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Cheque verification <Status :type="statusType.CHEQUE_VERIFICATION" />
        <div
          v-if="statusType.CHEQUE_VERIFICATION === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ chequeReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="kyc-approval-common-inner-content-wrapper">
            <div class="kyc-approval-common-image-wrapper">
              <img
                style="max-width: 400px; width: 100%; object-fit: cover"
                :src="chequeImage"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.CHEQUE_VERIFICATION)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.CHEQUE_VERIFICATION)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.SELFIE)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Selfie <Status :type="statusType.SELFIE" />
        <div
          v-if="statusType.SELFIE === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ selfieReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div class="kyc-approval-common-image-wrapper">
          <img
            style="max-width: 400px; width: 100%; object-fit: cover"
            :src="selfieImage"
          />
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.SELFIE)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.SELFIE)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.IPV)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Webcam verification (IPV) <Status :type="statusType.IPV" />
        <div
          v-if="statusType.IPV === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ ipvRejectionReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div
          class="kyc-approval-common-image-wrapper"
          style="display: flex; justify-content: space-evenly"
        >
          <div class="ipv-wrapper">
            <div class="instructions">
              Ensure that client's face and the below code are clearly visible.
            </div>
            <div class="code">{{ ipvCode }}</div>
          </div>
          <div>
            <img
              style="max-width: 400px; width: 100%; object-fit: cover"
              :src="ipvImage"
            />
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.IPV)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.IPV)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.SIGNATURE)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Signature <Status :type="statusType.SIGNATURE" />
        <div
          v-if="statusType.SIGNATURE === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ signatureReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div class="kyc-approval-common-image-wrapper">
          <img
            style="max-width: 400px; width: 100%; object-fit: cover"
            :src="signatureImage"
          />
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.SIGNATURE)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.SIGNATURE)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.PERSONAL_DETAILS)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Personal Details <Status :type="statusType.PERSONAL_DETAILS" />
        <div
          v-if="statusType.PERSONAL_DETAILS === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ personalDetailsReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Name:</div>
            <div class="client-details-key-text">Marital Status:</div>
            <div class="client-details-key-text">Father Name:</div>
            <div class="client-details-key-text">Mother Name:</div>
            <div class="client-details-key-text">Gender:</div>
            <div class="client-details-key-text">Annual Income:</div>
            <div class="client-details-key-text">Occupation:</div>
            <div class="client-details-key-text">Trading Experience:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">
              {{ personalDetails.name }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.maritalStatus }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.fatherName }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.motherNmae }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.gender }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.annualIncome }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.occupation }}
            </div>
            <div class="client-details-response-text">
              {{ personalDetails.tradingExperience }}
            </div>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.PERSONAL_DETAILS)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.PERSONAL_DETAILS)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.DECLARATIONS)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Declarations <Status :type="statusType.DECLARATIONS" />
        <div
          v-if="statusType.DECLARATIONS === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ declarationReason }}
        </div>
      </div>
      <div class="kyc-approval-common-inner-content-wrapper">
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Indian Citizen:</div>
            <div class="client-details-key-text">Politically Not Exposed:</div>
            <div class="client-details-key-text">Information Declaration:</div>
            <div class="client-details-key-text">
              Annual Information Declaration:
            </div>
            <div class="client-details-key-text">Account Authorization:</div>
            <div class="client-details-key-text">Active Proceedings:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">
              {{ declarationDetails.indianCitizen }}
            </div>
            <div class="client-details-response-text">
              {{ declarationDetails.politicallyExposed }}
            </div>
            <div class="client-details-response-text">
              {{ declarationDetails.informationDeclaration }}
            </div>
            <div class="client-details-response-text">
              {{ declarationDetails.annualIncomeDeclaration }}
            </div>
            <div class="client-details-response-text">
              {{ declarationDetails.accountAuthorization }}
            </div>
            <div class="client-details-response-text">
              {{ declarationDetails.activeProceedings }}
            </div>
          </div>
        </div>
      </div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.DECLARATIONS)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.DECLARATIONS)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.NOMINEE)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        Nominee <Status :type="statusType.NOMINEE" />
        <div
          v-if="statusType.NOMINEE === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ nomineeReason }}
        </div>
      </div>
      <div
        class="kyc-approval-common-inner-content-wrapper"
        v-for="(nominee, index) in nomineeDetails"
        :key="index"
      >
        <div style="display: flex">
          <div class="client-details-key-with-wrapper">
            <div class="client-details-key-text">Name:</div>
            <div class="client-details-key-text">Mobile No:</div>
            <div class="client-details-key-text">Relation:</div>
            <div class="client-details-key-text">DOB:</div>
            <div class="client-details-key-text">Share:</div>
            <div class="client-details-key-text">Email:</div>
            <div class="client-details-key-text">Address Line 1:</div>
            <div class="client-details-key-text">Address Line 2:</div>
            <div class="client-details-key-text">City:</div>
            <div class="client-details-key-text">State:</div>
            <div class="client-details-key-text">Country:</div>
            <div class="client-details-key-text">Pincode:</div>
            <div class="client-details-key-text">File:</div>
            <div class="client-details-key-text">File Password:</div>
          </div>
          <div class="client-details-response-with-wrapper">
            <div class="client-details-response-text">
              {{ nominee.name ? nominee.name : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.mobile ? nominee.mobile : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.relation ? nominee.relation : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.dob ? nominee.dob : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.share ? nominee.share : "-" }}%
            </div>
            <div class="client-details-response-text">
              {{ nominee.email ? nominee.email : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.address1 ? nominee.address1 : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.address2 ? nominee.address2 : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.city ? nominee.city : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.state ? nominee.state : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.country ? nominee.country : "-" }}
            </div>
            <div class="client-details-response-text">
              {{ nominee.pin ? nominee.pin : "-" }}
            </div>
            <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '12px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div style="font-size: 12px;">{{ nominee.document.name }}</div>
          <div style="margin-left: 50px">
            <a :href="nominee.document.url" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
            <div class="client-details-response-text">
              {{ nominee.password ? nominee.password : "Not Password Protected" }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!nomineeSelected">No Nominee Added</div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.NOMINEE)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.NOMINEE)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.SEGMENT_SELECTION)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        FNO <Status :type="statusType.SEGMENT_SELECTION" />
        <div
          v-if="statusType.SEGMENT_SELECTION === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ fnoReason }}
        </div>
      </div>
      <div v-if="fnoAdded" class="kyc-approval-common-inner-content-wrapper">
        <div class="future-options-select-segment-wrapper">
          <a-checkbox-group
            v-model:value="segmentValues"
            name="checkboxgroup"
            :options="segmentOptions"
            :disabled="true"
          />
        </div>
        <div>Clients 6 months bank statement</div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>{{ fnoDocumentName }}</div>
          <div style="margin-left: 50px">
            <a :href="fnoDocument" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
        <div style="margin-top: 15px" v-if="accounStatementPassword">
          Password : {{ accounStatementPassword }}
        </div>
      </div>
      <div v-if="!fnoAdded">No Fno Added</div>
      <div class="kyc-approval-common-approve-reject-btn-wrapper">
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.SEGMENT_SELECTION)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.SEGMENT_SELECTION)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div
      v-if="completedStages.includes(STAGES.STAGE_TYPE.ESIGN)"
      class="kyc-approval-common-section-main-wrapper"
    >
      <div
        class="kyc-approval-common-title-text"
        style="display: flex; align-items: center"
      >
        E Sign <Status :type="statusType.ESIGN" />
        <div
          v-if="statusType.ESIGN === STATUS.STATUS.REJECTED"
          class="kyc-rejected-reason-text"
        >
          {{ eSignReason }}
        </div>
      </div>
      <div
        v-if="esignDocument"
        class="kyc-approval-common-inner-content-wrapper"
      >
        <div>Clients digitally esigned document</div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>{{ esignDocumentName }}</div>
          <div style="margin-left: 50px">
            <a :href="esignDocument" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
        <div style="margin-top: 20px;">Clients DDPI document (DRAFT)</div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>{{ ddpiDocumentName }}</div>
          <div style="margin-left: 50px">
            <a :href="ddpiDocument" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
        <div style="margin-top: 20px;">Clients CVL KRA Application</div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>{{ cvlKraDocumentName }}</div>
          <div style="margin-left: 50px">
            <a :href="cvlKraDocument" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
        <div v-if="showClosureApplication">
        <div style="margin-top: 20px;">Client's account closure form</div>
        <div class="account-statement-file-with-download">
          <div>
            <file-pdf-outlined
              :style="{
                fontSize: '20px',
                marginRight: '10px',
                color: 'red',
              }"
            />
          </div>
          <div>{{ closeAccountDocumentName }}</div>
          <div style="margin-left: 50px">
            <a :href="closeAccountDocument" target="_blank"
              ><download-outlined
                :style="{
                  fontSize: '20px',
                  color: '#CC4B4C',
                  cursor: 'pointer',
                }"
            /></a>
          </div>
        </div>
      </div>
      </div>
      <div
        v-if="esignDocument"
        class="kyc-approval-common-approve-reject-btn-wrapper"
      >
        <div class="approve-btn-wrapper" style="margin-right: 15px">
          <a-button @click="acceptKycStage(STAGES.STAGE_TYPE.ESIGN)"
            >Approve</a-button
          >
        </div>
        <div class="reject-btn-wrapper">
          <a-button @click="onClickReject(STAGES.STAGE_TYPE.ESIGN)"
            >Reject</a-button
          >
        </div>
      </div>
    </div>
    <div>
      <a-form>
        <a-modal
          title=""
          width="500px"
          v-model:visible="acceptKycModal"
          :closable="false"
          centered
          footer=""
          destroyOnClose
          :afterClose="onCloseAcceptKycModal"
        >
          <div style="font-weight: 500; font-size: 16px; margin-bottom: 5px">
            Client UCC Id
          </div>
          <a-form-item v-bind="validateInfos.clientId">
            <a-input
              v-model:value="modelRef.clientId"
              placeholder="Please Enter Client UCC Id"
            />
          </a-form-item>
          <div class="kyc-reject-modal-main-wrapper">
            <div class="cancel-btn-wrapper" style="margin-right: 15px">
              <a-button @click="onCancelKycModal">Cancel</a-button>
            </div>
            <div class="submit-btn-wrapper">
              <a-button @click="onSubmitUccId">Submit</a-button>
            </div>
          </div>
        </a-modal>
      </a-form>
    </div>
    <div>
      <a-modal
        title=""
        width="500px"
        v-model:visible="rejectReason"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        :afterClose="onCloseRejectModal"
      >
        <a-form :model="formState" @finish="rejectKycStage">
          <a-form-item
            name="remarks"
            :rules="[{ required: true, message: 'Please enter reason' }]"
          >
            <a-textarea
              v-model:value="formState.remarks"
              placeholder="Please write reason"
              :rows="4"
            />
          </a-form-item>
          <div class="kyc-reject-modal-main-wrapper">
            <div class="cancel-btn-wrapper" style="margin-right: 15px">
              <a-button @click="onClickRejectCancel">Cancel</a-button>
            </div>
            <div class="submit-btn-wrapper">
              <a-button html-type="submit">Submit</a-button>
            </div>
          </div>
        </a-form>
      </a-modal>
    </div>
    <div>
      <a-modal
        title=""
        width="500px"
        v-model:visible="bowUserActivateModal"
        :closable="false"
        centered
        footer=""
        destroyOnClose
        :afterClose="onCloseBowModal"
      >
        <a-form :model="bowFormState" @finish="bowUserActivate">
          <a-form-item
            name="bowUserId"
            :rules="[{ required: true, message: 'Please Enter BOW User ID' }]"
          >
            <a-input
              v-model:value="bowFormState.bowUserId"
              placeholder="Please Enter BOW Client UCC Id"
            />
          </a-form-item>
          <div class="kyc-reject-modal-main-wrapper">
            <div class="cancel-btn-wrapper" style="margin-right: 15px">
              <a-button @click="onClickBowCancel">Cancel</a-button>
            </div>
            <div class="submit-btn-wrapper">
              <a-button html-type="submit">Submit</a-button>
            </div>
          </div>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { onMounted, ref, reactive, watch, computed } from "vue";
import { FilePdfOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import { useRoute } from "vue-router";
import { Form, message } from "ant-design-vue";
import STAGES from "../../../constants/stages";
import CONSTANTS from "../../../constants/constant";
import STATUS from "../../../constants/approvalStages";
import Status from "../../common/components/status.vue";
import services from "../../../services/apis";

const { useForm } = Form;

export default {
  components: {
    FilePdfOutlined,
    Status,
    DownloadOutlined,
  },
  setup() {
    const route = useRoute();
    const rejectReason = ref(false);
    const acceptKycModal = ref(false);
    const bowUserActivateModal = ref(false);
    const clientIdWithUrl = ref("");
    const rejectionStage = ref("");
    const mobileNumber = ref("");
    const emailId = ref("");
    const panName = ref("");
    const panNumber = ref("");
    const aadhaarNumber = ref("");
    const dateOfBirth = ref("");
    const uccCode = ref("");
    const panImage = ref("");
    const aadharFrontImage = ref("");
    const selfieImage = ref("");
    const chequeImage = ref("");
    const ipvImage = ref("");
    const signatureImage = ref("");
    const statusType = ref([]);
    const nomineeDetails = ref([]);
    const completedStages = ref([]);
    const nomineeSelected = ref(false);
    const fnoAdded = ref(false);
    const fnoDocument = ref("");
    const fnoDocumentName = ref("");
    const esignDocument = ref("");
    const esignDocumentName = ref("");
    const ddpiDocument = ref("");
    const ddpiDocumentName = ref("");
    const cvlKraDocument = ref("");
    const cvlKraDocumentName = ref("");
    const closeAccountDocument = ref("");
    const closeAccountDocumentName = ref("");
    const panReason = ref("");
    const aadharReason = ref("");
    const kraReason = ref("");
    const bankReason = ref("");
    const chequeReason = ref("");
    const selfieReason = ref("");
    const ipvRejectionReason = ref("");
    const signatureReason = ref("");
    const personalDetailsReason = ref("");
    const declarationReason = ref("");
    const nomineeReason = ref("");
    const fnoReason = ref("");
    const eSignReason = ref("");
    const accounStatementPassword = ref("");
    const acceptKycDisableState = ref(true);
    const bowActivate = ref(false);
    const activateKyc = ref(true);
    const bowActiveDisable = ref(false);
    const closeAccountDisable = ref(true);
    const showClosureApplication=ref(false);
    const segmentValues = reactive([]);
    const bankDetails = reactive({
      name: "",
      account: "",
      ifsc: "",
      micr: "",
    });
    const personalDetails = reactive({
      annualIncome: "",
      fatherName: "",
      gender: "",
      motherNmae: "",
      name: "",
      occupation: "",
      tradingExperience: "",
    });
    const declarationDetails = reactive({
      accountAuthorization: "",
      activeProceedings: "",
      annualIncomeDeclaration: "",
      indianCitizen: "",
      informationDeclaration: "",
      politicallyExposed: "",
    });
    const kraDetails = reactive({
      kycStatus: "",
      name: "",
      gender: "",
      dob: "",
      maritialStatus: "",
      mobile: "",
      email: "",
      fatherName: "",
      pan: "",
      nationality: "",
      residentStatus: "",
      annualIncome: "",
      occupation: "",
      politicallyExposed: false,
      city: "",
      pincode: "",
      state: "",
      country: "",
    });
    const ipvCode = ref("");
    watch(() => {
      const path = ref("");
      path.value = computed(() => route.path).value;
      const pathArray = path.value.split("/");
      const [a, b, c, d] = pathArray;
      clientIdWithUrl.value = d;
    });
    const segmentOptions = [
      {
        label: "Equity F&O",
        value: "EQUITY_FNO",
      },
      {
        label: "Commodity",
        value: "COMMODITY_FNO",
      },
      {
        label: "Currency",
        value: "CURRENCY_FNO",
      },
    ];
    const modelRef = reactive({
      clientId: "",
    });
    const rulesRef = reactive({
      clientId: [
        {
          required: true,
          message: "Please enter Client UCC Id",
        },
      ],
    });
    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    );
    const formState = reactive({
      remarks: "",
    });
    const bowFormState = reactive({
      bowUserId: "",
    });
    // const esignPdf = "https://www.clickdimensions.com/links/TestPDFfile.pdf";
    const fetchKycApplication = async (params) => {
      await services
        .fetchKycApplication({ id: params })
        .then((response) => {
          if (response.data.status === "IN_PROGRESS") {
            bowActivate.value = false;
            activateKyc.value = true;
          }
          if (response.data.status === "KYC_ACCEPTED") {
            bowActivate.value = true;
            activateKyc.value = false;
          }
          if (response.data.status === "ACTIVE") {
            bowActiveDisable.value = true;
            closeAccountDisable.value = false;
          }
          uccCode.value = response.data.clientId ? response.data.clientId : "-";
          const stages = response.data.stages;
          const accettKycBtnStatus = stages.find(
            (item) =>
              item.status === STATUS.STATUS.REJECTED ||
              item.status === STATUS.STATUS.PENDING_APPROVAL
          );
          if (accettKycBtnStatus) {
            acceptKycDisableState.value = true;
          } else {
            acceptKycDisableState.value = false;
          }
          completedStages.value = stages.map((item) => item.stage);
          statusType.value = stages.reduce((initialValue, stageName) => {
            initialValue[stageName.stage] = stageName.status;
            return initialValue;
          }, {});
          const getMobileObjObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.VALIDATE_MOBILE
          );
          if (getMobileObjObj) {
            mobileNumber.value = getMobileObjObj.data.mobile
              ? getMobileObjObj.data.mobile
              : "-";
            const getEmailObj = stages.find(
              (item) => item.stage === STAGES.STAGE_TYPE.VALIDATE_EMAIL
            );
            emailId.value = getEmailObj.data.email
              ? getEmailObj.data.email
              : "-";
          }

          const getPanObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.PAN_VALIDATION
          );
          if (getPanObj) {
            panName.value = getPanObj.data.name ? getPanObj.data.name : "-";
            dateOfBirth.value = getPanObj.data.dob ? getPanObj.data.dob : "-";
            panNumber.value = getPanObj.data.pan ? getPanObj.data.pan : "-";
            panImage.value = getPanObj.documents[0].url;
            if (getPanObj.rejectionRemarks) {
              panReason.value = getPanObj.rejectionRemarks
                ? getPanObj.rejectionRemarks
                : "";
            } else {
              panReason.value = "";
            }
          }

          const cheque = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.CHEQUE_VERIFICATION
          );
          if (cheque) {
            chequeImage.value =
              cheque.documents && cheque.documents.length ? cheque.documents[0].url : "";
            chequeReason.value = cheque.rejectionRemarks
              ? cheque.rejectionRemarks
              : "";
          }

          const getBankObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.BANK_DETAILS
          );
          if (getBankObj) {
            let banObj;
            banObj = {
              name: getBankObj.data.name ? getBankObj.data.name : "-",
              account: getBankObj.data.account ? getBankObj.data.account : "-",
              ifsc: getBankObj.data.ifsc ? getBankObj.data.ifsc : "-",
              micr: getBankObj.data.micr ? getBankObj.data.micr : "-",
              type: getBankObj.data.type ? getBankObj.data.type : "-",
            };
            Object.assign(bankDetails, banObj);
            if (getBankObj.rejectionRemarks) {
              bankReason.value = getBankObj.rejectionRemarks
                ? getBankObj.rejectionRemarks
                : "";
            } else {
              bankReason.value = "";
            }
          }

          const getAadhaarObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.ADDRESS_PROOF
          );
          if (getAadhaarObj) {
            aadhaarNumber.value = getAadhaarObj.data.aadhaar
              ? getAadhaarObj.data.aadhaar
              : "-";
            aadharFrontImage.value = getAadhaarObj.documents[0].url;
            if (getAadhaarObj.rejectionRemarks) {
              aadharReason.value = getAadhaarObj.rejectionRemarks
                ? getAadhaarObj.rejectionRemarks
                : "";
            } else {
              aadharReason.value = "";
            }
          }

          const getSelfieObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.SELFIE
          );
          if (getSelfieObj) {
            selfieImage.value = getSelfieObj.documents[0].url;
            if (getSelfieObj.rejectionRemarks) {
              selfieReason.value = getSelfieObj.rejectionRemarks
                ? getSelfieObj.rejectionRemarks
                : "";
            } else {
              selfieReason.value = "";
            }
          }

          const ipv = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.IPV
          );
          if (ipv) {
            ipvImage.value =
              ipv.documents && ipv.documents.length ? ipv.documents[0].url : "";
            ipvRejectionReason.value = ipv.rejectionRemarks
              ? ipv.rejectionRemarks
              : "";
            ipvCode.value = ipv.data.code
              ? ipv.data.code
              : ipv.data.__data
              ? ipv.data.__data.code
              : "";
          }

          const getSignatureObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.SIGNATURE
          );
          if (getSignatureObj) {
            signatureImage.value = getSignatureObj.documents[0].url;
            if (getSignatureObj.rejectionRemarks) {
              signatureReason.value = getSignatureObj.rejectionRemarks
                ? getSignatureObj.rejectionRemarks
                : "";
            } else {
              signatureReason.value = "";
            }
          }

          const getpersonalDetailsObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.PERSONAL_DETAILS
          );
          if (getpersonalDetailsObj) {
            let personalDetailsObj;
            personalDetailsObj = {
              annualIncome: getpersonalDetailsObj.data.annualIncome
                ? getpersonalDetailsObj.data.annualIncome
                : "-",
              fatherName: getpersonalDetailsObj.data.fatherName
                ? getpersonalDetailsObj.data.fatherName
                : "-",
              gender: getpersonalDetailsObj.data.gender
                ? getpersonalDetailsObj.data.gender
                : "-",
              motherNmae: getpersonalDetailsObj.data.motherNmae
                ? getpersonalDetailsObj.data.motherNmae
                : "-",
              name: getpersonalDetailsObj.data.name
                ? getpersonalDetailsObj.data.name
                : "-",
              occupation: getpersonalDetailsObj.data.occupation
                ? getpersonalDetailsObj.data.occupation
                : "-",
              tradingExperience: getpersonalDetailsObj.data.tradingExperience
                ? getpersonalDetailsObj.data.tradingExperience
                : "-",
              maritalStatus: getpersonalDetailsObj.data.maritalStatus
                ? getpersonalDetailsObj.data.maritalStatus
                : "-",
            };
            Object.assign(personalDetails, personalDetailsObj);
            if (getpersonalDetailsObj.rejectionRemarks) {
              personalDetailsReason.value =
                getpersonalDetailsObj.rejectionRemarks
                  ? getpersonalDetailsObj.rejectionRemarks
                  : "";
            } else {
              personalDetailsReason.value = "";
            }
          }

          const getDeclarationObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.DECLARATIONS
          );
          if (getDeclarationObj) {
            let declarationObj;
            declarationObj = {
              accountAuthorization: getDeclarationObj.data.accountAuthorization
                ? "Yes"
                : "No",
              activeProceedings: getDeclarationObj.data.activeProceedings
                ? "Yes"
                : "No",
              annualIncomeDeclaration: getDeclarationObj.data
                .annualIncomeDeclaration
                ? "Yes"
                : "No",
              indianCitizen: getDeclarationObj.data.indianCitizen
                ? "Yes"
                : "No",
              informationDeclaration: getDeclarationObj.data
                .informationDeclaration
                ? "Yes"
                : "No",
              politicallyExposed: getDeclarationObj.data.politicallyExposed
                ? "Yes"
                : "No",
            };
            Object.assign(declarationDetails, declarationObj);
            if (getDeclarationObj.rejectionRemarks) {
              declarationReason.value = getDeclarationObj.rejectionRemarks
                ? getDeclarationObj.rejectionRemarks
                : "";
            } else {
              declarationReason.value = "";
            }
          }

          const getnomineeObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.NOMINEE
          );
          if (getnomineeObj) {
            nomineeSelected.value = getnomineeObj.data.nomineeRequired;
            if (getnomineeObj.data.nomineeRequired) {
              nomineeDetails.value = getnomineeObj.data.nominees;
              console.log(nomineeDetails.value)
            }
            if (getnomineeObj.rejectionRemarks) {
              nomineeReason.value = getnomineeObj.rejectionRemarks
                ? getnomineeObj.rejectionRemarks
                : "";
            } else {
              nomineeReason.value = "";
            }
          }
          const getkraObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.KRA
          );
          if (getkraObj) {
            let kraObj;
            kraObj = {
              kycStatus: getkraObj.data.kycStatus
                ? getkraObj.data.kycStatus
                : "-",
              name: getkraObj.data.name ? getkraObj.data.name : "-",
              gender: getkraObj.data.gender ? getkraObj.data.gender : "-",
              dob: getkraObj.data.dob ? getkraObj.data.dob : "-",
              maritialStatus: getkraObj.data.maritialStatus
                ? getkraObj.data.maritialStatus
                : "-",
              mobile: getkraObj.data.mobile ? getkraObj.data.mobile : "-",
              email: getkraObj.data.email ? getkraObj.data.email : "-",
              fatherName: getkraObj.data.fatherName
                ? getkraObj.data.fatherName
                : "-",
              pan: getkraObj.data.pan ? getkraObj.data.pan : "-",
              nationality: getkraObj.data.nationality
                ? getkraObj.data.nationality
                : "-",
              residentStatus: getkraObj.data.residentStatus
                ? getkraObj.data.residentStatus
                : "-",
              annualIncome: getkraObj.data.annualIncome
                ? getkraObj.data.annualIncome
                : "-",
              occupation: getkraObj.data.occupation
                ? getkraObj.data.occupation
                : "-",
              politicallyExposed: getkraObj.data.politicallyExposed,
              city: getkraObj.data.city ? getkraObj.data.city : "-",
              pincode: getkraObj.data.pincode ? getkraObj.data.pincode : "-",
              state: getkraObj.data.state ? getkraObj.data.state : "-",
              country: getkraObj.data.country ? getkraObj.data.country : "-",
            };
            Object.assign(kraDetails, kraObj);
            if (getkraObj.rejectionRemarks) {
              kraReason.value = getkraObj.rejectionRemarks
                ? getkraObj.rejectionRemarks
                : "";
            } else {
              kraReason.value = "";
            }
          }
          const getFnoObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.SEGMENT_SELECTION
          );
          if (getFnoObj) {
            fnoAdded.value = getFnoObj.data.fnoRequired;
            if (fnoAdded.value) {
              fnoDocument.value = getFnoObj.documents[0].url;
              accounStatementPassword.value = getFnoObj.documents[0].password;
              fnoDocumentName.value = getFnoObj.documents[0].name;
            }
            const segments = getFnoObj.data.segments;
            if (segments.includes("BFO-FUT")) {
              segmentValues.push("EQUITY_FNO");
            }
            if (segments.includes("BCO-FUT")) {
              segmentValues.push("COMMODITY_FNO");
            }
            if (segments.includes("BCD-FUT")) {
              segmentValues.push("CURRENCY_FNO");
            }
          }
          if (getFnoObj.rejectionRemarks) {
            fnoReason.value = getFnoObj.rejectionRemarks
              ? getFnoObj.rejectionRemarks
              : "";
          } else {
            fnoReason.value = "";
          }

          const getEsignObj = stages.find(
            (item) => item.stage === STAGES.STAGE_TYPE.ESIGN
          );
          if (getEsignObj) {
            getEsignObj.documents.map(document => {
              if (document.documentType === 'ESIGNED' || document.documentType === 'ESIGN') {
                esignDocument.value = document.url;
                esignDocumentName.value = document.name;
              }

              if (document.documentType === 'DDPI_DRAFT') {
                ddpiDocument.value = document.url;
                ddpiDocumentName.value = document.name;
              }

              if (document.documentType === 'NONKRA_ESIGNED') {
                cvlKraDocument.value = document.url;
                cvlKraDocumentName.value = document.name;
              }

              if (document.documentType === 'ACCOUNT_CLOSE_ESIGNED') {
                showClosureApplication.value = true;
                closeAccountDocument.value = document.url;
                closeAccountDocumentName.value = document.name;
              }
            });
          }
          if (getEsignObj.rejectionRemarks) {
            eSignReason.value = getEsignObj.rejectionRemarks
              ? getEsignObj.rejectionRemarks
              : "";
          } else {
            eSignReason.value = "";
          }
        })
        .catch(() => {});
    };
    onMounted(() => {
      window.scrollTo(0, 0);
      fetchKycApplication(clientIdWithUrl.value);
    });
    const acceptKyc = () => {
      acceptKycModal.value = true;
    };
    const syncToKra = async () => {
      services.syncToKra({id: clientIdWithUrl.value})
        .then(() => {
          message.success(`Successfully synced client's record to KRA database`, 2);
          bowActivate.value = true;
          activateKyc.value = false;
        })
        .catch(() => {
          message.error(`Failed to sync client's kyc record to KRA database`);
        });
    };
    const bowUserActivated = () => {
      bowUserActivateModal.value = true;
    };
    const onCancelKycModal = () => {
      acceptKycModal.value = false;
    };
    const onSubmitUccId = () => {
      validate().then(() => {
        services
          .acceptKyc({ id: clientIdWithUrl.value, data: modelRef })
          .then(() => {
            acceptKycModal.value = false;
            message.success("KYC Accepted Successfully", 2);
            bowActivate.value = true;
            activateKyc.value = false;
          })
          .catch(() => {});
      });
    };
    const bowUserActivate = () => {
      services
        .bowUserActivated({ id: clientIdWithUrl.value, data: bowFormState })
        .then(() => {
          message.success("BOW User Activated Successfully", 2);
          bowUserActivateModal.value = false;
          fetchKycApplication(clientIdWithUrl.value);
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 2);
        });
    };
    const acceptKycStage = (stage) => {
      const payload = {
        stage: stage,
      };
      services
        .acceptKycStage({ id: clientIdWithUrl.value, data: payload })
        .then((response) => {
          if (response.data.success === true) {
            message.success("Approved Successfully", 2);
            fetchKycApplication(clientIdWithUrl.value);
          }
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 2);
        });
    };
    const rejectKycStage = (values) => {
      const payload = {
        stage: rejectionStage.value,
        remarks: values.remarks,
      };
      services
        .rejectKycStage({ id: clientIdWithUrl.value, data: payload })
        .then((response) => {
          if (response.data.success === true) {
            message.success("Rejected Successfully", 2);
            fetchKycApplication(clientIdWithUrl.value);
          }
          rejectReason.value = false;
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 2);
        });
    };
    const closeAccount = () => {
      services
        .closeAccount({ id: clientIdWithUrl.value })
        .then(() => {
          message.success("Client's account closed successfully!", 2);
          fetchKycApplication(clientIdWithUrl.value);
        })
        .catch((e) => {
          message.error(e.response.data.error.message, 2);
        });
    };
    const onClickReject = (stage) => {
      rejectionStage.value = stage;
      rejectReason.value = true;
    };
    const onClickRejectCancel = () => {
      rejectReason.value = false;
    };
    const onClickBowCancel = () => {
      bowUserActivateModal.value = false;
    };
    const onClickSubmit = () => {
      rejectReason.value = false;
    };
    const onCloseAcceptKycModal = () => {
      modelRef.clientId = "";
    };
    const onCloseRejectModal = () => {
      formState.remarks = "";
    };
    const onCloseBowModal = () => {
      bowFormState.bowUserId = "";
    };
    return {
      // esignPdf,
      acceptKyc,
      rejectReason,
      onClickReject,
      bowUserActivateModal,
      onClickRejectCancel,
      onClickSubmit,
      acceptKycModal,
      onSubmitUccId,
      onCancelKycModal,
      validateInfos,
      resetFields,
      modelRef,
      acceptKycStage,
      STAGES,
      rejectKycStage,
      closeAccount,
      formState,
      bowFormState,
      statusType,
      mobileNumber,
      emailId,
      panName,
      dateOfBirth,
      panNumber,
      panImage,
      bankDetails,
      aadharFrontImage,
      selfieImage,
      chequeImage,
      signatureImage,
      personalDetails,
      declarationDetails,
      nomineeDetails,
      completedStages,
      kraDetails,
      nomineeSelected,
      fnoAdded,
      fnoDocument,
      fnoDocumentName,
      esignDocument,
      aadhaarNumber,
      panReason,
      STATUS,
      aadharReason,
      kraReason,
      bankReason,
      chequeReason,
      selfieReason,
      signatureReason,
      personalDetailsReason,
      declarationReason,
      nomineeReason,
      fnoReason,
      eSignReason,
      acceptKycDisableState,
      onCloseRejectModal,
      onCloseBowModal,
      onCloseAcceptKycModal,
      bowActivate,
      activateKyc,
      syncToKra,
      bowUserActivated,
      bowUserActivate,
      onClickBowCancel,
      segmentOptions,
      segmentValues,
      esignDocumentName,
      accounStatementPassword,
      bowActiveDisable,
      closeAccountDisable,
      uccCode,
      ipvImage,
      ipvRejectionReason,
      ipvCode,
      ddpiDocument,
      ddpiDocumentName,
      cvlKraDocument,
      cvlKraDocumentName,
      closeAccountDocument,
      closeAccountDocumentName,
    };
  },
};
</script>
<style lang="scss">
@import "../styles/adminKycApproval.scss";
</style>
